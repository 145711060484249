import React,{ useState,useEffect } from 'react';
import './App.css';
import {Container,Row,Col,FormControl,Card,Modal,Badge} from 'react-bootstrap';

const Page =1;

function App() {

  const [show, setShow] = useState(false);
  const [state,setState] = useState([]);
  const [filteredResults,setfilteredResults] = useState([]);
  const [keyword,setKeyword] = useState('');
  const [page,setPage] = useState(Page);
  const [product,setProduct] = useState([]);
  const handleClose = () => setShow(false);
  
  useEffect(()=>{
    fetch(`https://miniapp.newnormal-plus.com/rest/product/?page=${page}&q=${keyword}`,{
      method: 'GET',     
    })
    .then(res => res.json())
    .then(json => setState([...state,...json]))
  },[page,keyword]);


  const searchItems = (searchValue) => {
    setState([]);
    setfilteredResults([]);
    setKeyword(searchValue);
    setPage(1);
    
    fetch(`https://miniapp.newnormal-plus.com/rest/product/?page=${page}&q=${keyword}`,{
      method: 'GET',     
    })
    .then(res => res.json())
    .then(json => setfilteredResults([filteredResults,...json]))

    // if (keyword !== '' && keyword.length > 1) {
    //     setPage(1);
    //     const filteredData = state.filter((item,index) => {
          
    //         return Object.values(item).join('').toLowerCase().includes(keyword.toLowerCase())

    //     })


    //     setfilteredResults(filteredData)

    // }
}
  const viewProduct =(pid) =>{
    fetch(`https://miniapp.newnormal-plus.com/rest/productdetail/?pid=${pid}`,{
      method: 'GET',     
    })
    .then(res => res.json())
    .then((json) => {
      setProduct(json);
      setShow(true);
    })
  }

  const scrollToEnd = () =>{
    setPage(page+1);
    
  }  

  window.onscroll = function(){
    if(window.innerHeight + document.documentElement.scrollTop === document.documentElement.offsetHeight){
      scrollToEnd()
    }
  }

  return (
    <div className="App">
      <Container>
        <div className='float-search'>
        <Row>
          <Col className='text-center my-2'><img src="https://miniapp.newnormal-plus.com/static/assets/images/logo/logo.png?v=1640444038" alt='logo' width={120}/></Col>
        </Row>
        <Row>
          <Col className='mb-2'>    
              <FormControl
              type='text'
              value={keyword}
              className="search"
              placeholder="ค้นหา"
              onChange={(e) => searchItems(e.target.value)}
          />
          </Col>
        </Row>
        </div>
        <div className='wrap-product'>
        <Row  xs={2} md={4}>
        {keyword.length > 0 ? (
                    filteredResults.map((item,i) => {
                      if(i > 0){
                        return (
                          <Col key={i} className='mb-2'>
                          <Card className='card-thumbnail' onClick={() => viewProduct(item.id)}>
                            <Card.Body className="p-0">
                              <img src={item.imageshow_cover} alt='' className='w-100 mb-1 image-product'/>
                            </Card.Body>
                            <div>
                              <p className='m-0 px-2 title-sku'>{item.barcode_product}</p>
                              <p className='m-0 px-2 title-name'>{item.name_product}</p>
                            </div>
                          </Card>
                          </Col>     
                        );
                      }
                    })
                ) : (
                    state.map((item,i) => {
                        return (
                          <Col key={i} className='mb-2'>
                          <Card className='card-thumbnail' onClick={() => viewProduct(item.id)}>
                            <Card.Body className="p-0">
                              <img src={item.imageshow_cover} alt='' className='w-100 mb-1 image-product'/>
                            </Card.Body>
                            <div>
                              <p className='m-0 px-2 title-sku'>{item.barcode_product}</p>
                              <p className='m-0 px-2 title-name'>{item.name_product}</p>
                            </div>
                          </Card>
                          </Col>     
                        )
                    })
                )}

        </Row>
        </div>

      </Container>

      <Modal 
      show={show} 
      onHide={handleClose}
      aria-labelledby="contained-modal-title-vcenter"
      centered 
      >
        <Modal.Body>
        <span className='close-modal' bg="danger" onClick={handleClose}>&times;</span>
            <img src={product.img} alt='' className='w-100 mb-1'/>
            <div>
              <p className='m-0 title-sku-modal'>{product.sku}</p>
              <p className='m-0 title-name-modal mb-3'>{product.name}</p>
            </div>
            <Row  xs={2} md={2}>
              <Col>
                <p className='m-0 title-header'>คลังสินค้าหลัก</p>
                <p className='m-0 title-content'>{product.warehouse}</p>
              </Col>
              <Col>
                <p className='m-0 title-header'>พร้อมขาย</p>
                <p className='m-0 title-content'>{product.qty_sale}</p>
              </Col>
            </Row>
            <Row  xs={2} md={2}>
            <Col>
                <p className='m-0 title-header'>คงเหลือทั้งหมด</p>
                <p className='m-0 title-content'>{product.qty}</p>
              </Col>
              <Col>
                <p className='m-0 title-header'>คงเหลือ ERP</p>
                <p className='m-0 title-content'>{product.qty_ERP}</p>
                <Badge bg="warning" text="dark">{product.update}</Badge>
              </Col>
            </Row>
        </Modal.Body>
      </Modal>

    </div>
  );
}

export default App;
